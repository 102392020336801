import PresentAPI from "@/api/present";
import _ from "lodash";
import moment from "moment";

// initial state
const state = () => ({
  list: [],
  history: [],
  receives: [],
});

// getters
const getters = {
  getList: (state) => state.list,
  getHistory: (state) => state.history,
  getReceives: (state) => state.receives,
};

// actions
const actions = {
  async list({ state, commit }, payload) {
    try {
      const res = await PresentAPI.list({ ascending: payload });
      if (res.data) {
        commit("setList", res.data.list);
      }
    } catch (e) {
      throw e;
    }
  },
  async history({ commit }, params) {
    try {
      const res = await PresentAPI.history();
      if (res.data) {
        commit("setHistory", res.data.list);
      }
    } catch (e) {
      throw e;
    }
  },
  async receive({ commit }, payload) {
    try {
      const _res = await PresentAPI.receive({ present_ids: payload });
      if (_res.data) {
        const res = _.orderBy(
          _res.data.result,
          (item) => moment(item.sendTime),
          "desc"
        );
        commit("setReceives", res);
      }
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },
  setHistory(state, data) {
    state.history = data;
  },
  setReceives(state, data) {
    state.receives = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
