import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueToast from 'vue-toast-notification';
import 'sweetalert2/src/sweetalert2.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/assets/scss/style.scss';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'smart-app-banner/dist/smart-app-banner.css';
/**
 *
 */
// Core
import App from './App.vue';
import router from './router';
import store from './store';
import { initializeAxios } from '@/api/axios';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';
import VueMeta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';
import moment from 'moment';
import '@/directive/currency';
import '@/directive/ellipsis';
import '@/directive/date';
import '@/directive/link';
import '@/directive/ccExpiration';
import '@/directive/format';
import '@/directive/texture';
import VueYouTubeEmbed from 'vue-youtube-embed';
import CommonButton from '@/components/CommonButton.vue';
import { longClickDirective } from 'vue-long-click';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import {
  faEnvelope,
  faCopyright,
  faComment,
  faHeart as faHeartRegular
} from '@fortawesome/free-regular-svg-icons';
import {
  fas,
  faEllipsisVertical,
  faEllipsis,
  faHeart,
  faAngleLeft,
  faAngleRight,
  faBars,
  faPlus,
  faXmark,
  faShareNodes,
  faPencil,
  faTrash,
  faAngleUp,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */
library.add(
  faEllipsisVertical,
  faEllipsis,
  faHeart,
  faComment,
  faAngleLeft,
  faAngleRight,
  faEnvelope,
  faBars,
  faCopyright,
  faPlus,
  faXmark,
  faShareNodes,
  faPencil,
  faTrash,
  faHeartRegular,
  faAngleUp,
  faAngleDown,
  fas
);
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('CommonButton', CommonButton);

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueCroppie);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.use(VueSocialSharing);
Vue.use(VueYouTubeEmbed);
Vue.use(VueToast);
const longClickInstance = longClickDirective({ delay: 800, interval: 0 });
Vue.directive('longclick', longClickInstance);
moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});
initializeAxios(router);
window.onerror = function myErrorHandler (errorMsg, url, lineNumber) {
  console.log('===============');
  return false;
};
// eslint-disable-next-line node/handle-callback-err
Vue.config.errorHandler = function (err, vm, info) {
  console.error('=====', err);
};
Vue.config.devtools = process.env.VUE_APP_ENV_MODE !== 'production';
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
