import CollectionAPI from '@/api/collection';
import { cloneDeep } from 'lodash';

// initial state
const state = () => ({
  photos: null,
  availableStorys: null,
  privilegeStorys: null,
  privilegeEpisode: null,
  historyStorys: null,
  movies: null,
  tickets: null,
  ticket: null,
  address: null,
  specials: null,
  historyMovies: null
});

// getters
const getters = {
  photos: (state) => state.photos,
  movies: (state) => state.movies,
  historyMovies:(state) => state.historyMovies,
  historyStorys: (state) => state.historyStorys,
  availableStorys: (state) => state.availableStorys,
  privilegeStorys: (state) => state.privilegeStorys,
  privilegeEpisode: (state) => state.privilegeEpisode,
  tickets: (state) => state.tickets,
  ticket: (state) => state.ticket,
  address: (state) => state.address,
  specials: (state) => state.specials
};

// actions
const actions = {
  /**
   * データ取得ACTIONS
   */
  async ticket({ state, commit }, id) {
    try {
      const res = await CollectionAPI.ticket(id);
      if (res.data) {
        commit('set', { data: res.data, key: 'ticket' });
      }
    } catch (e) {
      throw e;
    }
  },
  async photoList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.photo(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'photos' });
      }
    } catch (e) {
      throw e;
    }
  },
  async movieList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.movie(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'movies' });
      }
    } catch (e) {
      throw e;
    }
  },
  async historyMovieList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.historyMovies(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'historyMovies' });
      }
    } catch (e) {
      throw e;
    }
  },
  async ticketList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.ticketList(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'tickets' });
      }
    } catch (e) {
      throw e;
    }
  },
  async specialList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.specialList(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'specials' });
      }
    } catch (e) {
      throw e;
    }
  },
  async historyStoryList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.historyStory(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'historyStorys' });
      }
    } catch (e) {
      throw e;
    }
  },
  async availableStoryList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.availableStory(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'availableStorys' });
      }
    } catch (e) {
      throw e;
    }
  },
  async privilegeStoryList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.privilegeStory(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'privilegeStorys' });
      }
    } catch (e) {
      throw e;
    }
  },
  async privilegeEpisode({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.privilegeEpisode(payload);
      if (res.data) {
        commit('set', { data: res.data, key: 'privilegeEpisode' });
      }
    } catch (e) {
      throw e;
    }
  },
  /**
   * データ追加ACTION
   */
  async addPhotoList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.photo(payload);
      if (res) {
        commit('addPhotoList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async addMovieList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.movie(payload);
      if (res) {
        commit('addMovieList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async addHistoryMovieList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.historyMovies(payload);
      if (res) {
        commit('addHistoryMovieList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async addTicketList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.ticketList(payload);
      if (res) {
        commit('addTicketList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async addSpecialList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.specialList(payload);
      if (res.data) {
        commit('addSpecialList', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async addPrivilegeStoryList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.privilegeStory(payload);
      if (res) {
        commit('addPrivilegeStoryList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async addHistoryStoryList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.historyStory(payload);
      if (res) {
        commit('addHistoryStoryList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async addAvailableStoryList({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.availableStory(payload);
      if (res) {
        commit('addAvailableStoryList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async setAddress({ state, commit }, payload = null) {
    try {
      await CollectionAPI.setAddressInfo(payload);
    } catch (e) {
      throw e;
    }
  },
  async getAddress({ state, commit }, payload = null) {
    try {
      const res = await CollectionAPI.getAddressInfo(payload);
      commit('set', { data: res.data, key: 'address' });
    } catch (e) {
      throw e;
    }
  },
  resetState({ state, commit }, payload = null) {
    state.photos = null;
    state.availableStorys = null;
    state.privilegeStorys = null;
    state.privilegeEpisode = null;
    state.historyStorys = null;
    state.movies = null;
    state.tickets = null;
    state.ticket = null;
    state.address = null;
    state.specials = null;
    state.historyMovies = null;
  }
};

const mutations = {
  set(state, data) {
    state[data.key] = data.data;
  },
  addPhotoList(state, data) {
    const photos = cloneDeep(state.photos);
    photos.photos.push(...data.photos);
    photos.seek = data.seek;
    state.photos = photos;
  },
  addAvailableStoryList(state, data) {
    const availableStorys = cloneDeep(state.availableStorys);
    availableStorys.storyGroups.push(...data.storyGroups);
    availableStorys.seek = data.seek;
    state.availableStorys = availableStorys;
  },
  addPrivilegeStoryList(state, data) {
    const privilegeStorys = cloneDeep(state.privilegeStorys);
    privilegeStorys.storyPrivilegeGroups.push(...data.storyPrivilegeGroups);
    privilegeStorys.seek = data.seek;
    state.privilegeStorys = privilegeStorys;
  },
  addHistoryStoryList(state, data) {
    const historyStorys = cloneDeep(state.historyStorys);
    historyStorys.storyGroups.push(...data.storyGroups);
    historyStorys.seek = data.seek;
    state.historyStorys = historyStorys;
  },
  addMovieList(state, data) {
    const movies = cloneDeep(state.movies);
    movies.movies.push(...data.movies);
    movies.seek = data.seek;
    state.movies = movies;
  },
  addHistoryMovieList(state, data) {
    const historyMovies = cloneDeep(state.historyMovies);
    historyMovies.videos.push(...data.videos);
    historyMovies.seek = data.seek;
    state.historyMovies = historyMovies;
  },
  addTicketList(state, data) {
    const tickets = cloneDeep(state.tickets);
    tickets.tickets.push(...data.tickets);
    tickets.seek = data.seek;
    state.tickets = tickets;
  },
  addSpecialList(state, data) {
    const specials = cloneDeep(state.specials);
    specials.specials.push(...data.specials);
    specials.seek = data.seek;
    state.specials = specials;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
