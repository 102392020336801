import Vue from 'vue';
import axios from 'axios';
import { Service } from 'axios-middleware';
import Cookies from 'js-cookie';
import { ERROR_MESSAGE, ERROR_TITLE } from '@/config/errorMessage';
import qs from 'qs';
import store from '@/store/index';
import ua from 'ua-parser-js';
import { omitBy, isNil } from 'lodash';
import { deviceOS } from '@/util';

// axiosで重ね合わせのオブジェクトパラメーターの不具合対応
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    const result = omitBy(params, isNil);
    return qs.stringify(result, {
      encode: false,
      allowDots: true
    });
  };
  // config.timeout = 15000;
  return config;
});

const service = new Service(axios);
class AuthMiddleware {
  constructor (http, router) {
    this.http = http;
    this.count = 0;
    this.router = router;
  }

  onRequest (config) {
    if (!config.disableLoad) {
      this.count++;
      store.commit('loading/show', { root: true });
    }
    if (config.refresh) {
      config.headers.Authorization = `Bearer ${Cookies.get('refreshToken')}`;
    } else {
      config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    }
    const agent = ua(navigator.userAgent);
    // os_type 1：iOS、2：Android、3：PC（Windows、macOSなど）、0：その他
    // market Google play、Apple Store、WEB決済
    const device = store.getters['device/get'];
    let osType = 3;
    let market = 'WEB';
    const os = deviceOS();
    if (device === 'web') {
      osType = 3;
      market = 'WEB';
    } else {
      switch (os) {
        case 'ios':
          osType = 1;
          market = 'Apple Store';
          break;
        case 'android':
          osType = 2;
          market = 'Google play';
          break;
      }
    }
    config.headers = {
      ...config.headers,
      'x-app-language': navigator.language,
      'x-os-location': navigator.language,
      'x-os-type': osType,
      'x-app-version': 2,
      'x-app-version-detail': 1,
      'x-player-id': store.getters['auth/accountId'],
      'x-device-id': '',
      'x-device-ad-id': '',
      'x-os-version': `${agent.os.name} ${agent.os.version}`,
      'x-device-model': agent.device.model,
      'x-market': market,
      'x-scene': this.router.currentRoute.path
    };
    return config;
  }

  async onResponse (response) {
    if (!response.config.disableLoad) {
      setTimeout(() => {
        this.count--;
        if (this.count === 0) {
          store.commit('loading/close', { root: true });
        }
        // eslint-disable-next-line no-magic-numbers
      }, 100);
    }
    return response;
  }

  async onResponseError (err) {
    const device = store.getters['device/get'];
    if (err.code === 'ECONNABORTED' && err.message.includes('timeout')) {
      this.count--;
      if (this.count === 0) {
        store.commit('loading/close', { root: true });
      }
      Vue.swal({
        allowOutsideClick: false,
        title: '通信タイムアウト',
        text: '通信がタイムアウトしました。\n通信環境が良いところで、再度お試しください。',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      });
      throw err;
    }
    let errorData = null;
    try {
      errorData = err.response.data;
      if (typeof errorData === 'string') {
        errorData = JSON.parse(errorData);
      }
    } catch (e) {
      this.count--;
      if (this.count === 0) {
        store.commit('loading/close', { root: true });
      }
      Vue.swal({
        allowOutsideClick: false,
        title: '原因不明のエラー',
        text: device === 'web' ? '原因不明のエラーが発生しました。\n ページを更新してもう一度おためしてください。' : '原因不明のエラーが発生しました。\n アプリの再起動を行ってください。',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      });
      throw err;
    }
    if (!err.config.disableLoad) {
      this.count--;
      if (this.count === 0) {
        store.commit('loading/close', { root: true });
      }
    }
    if (errorData.message === 'UnderMaintenance' && !err.config.maintenance) {
      await store.dispatch('maintenance/list');
    } else if (
      errorData.message === 'AcquireTimeExpired'
      || errorData.message === 'AlreadyAcquired'
      || errorData.message === 'NoAssetToAcquire'
    ) {
      await store.dispatch('notification/acquireError', errorData);
    } else if (errorData.message === 'SuspendAccount') {
      Vue.swal({
        allowOutsideClick: false,
        title: 'エラー',
        text: '現在このアカウントの利用が停止されています。\n「確認」でログアウトされます。',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: '確認',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      }).then((result) => {
        store.dispatch('profile/reset');
        store.dispatch('auth/logout');
        this.router.push({ path: '/login' });
      });
    } else if (errorData.message === 'TimeExpiredPasswordReset') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        text: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      }).then((result) => {
        store.dispatch('profile/reset');
        store.dispatch('auth/logout');
        Cookies.remove('pin-code-cache');
        this.router.push({ path: '/top' });
      });
    } else if (
      (errorData.message === 'GachaUnavailable'
        || errorData.message === 'FailedPrecondition')
      && !err.config.silent
    ) {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        text: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'purple'
        }
      }).then(async (result) => {
        await store.dispatch('gacha/setError', errorData);
      });
    } else if (errorData.message === 'NoGachaItemToDraw') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        text: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'purple'
        }
      });
      throw err;
    } else if (errorData.message === 'UnavailablePeriod') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        text: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'purple'
        }
      }).then((result) => {
        throw err;
        // this.router.back();
      });
    } else if (errorData.message === 'NotExistsPassword') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        html: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'ログイン画面へ',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      }).then((result) => {
        store.dispatch('profile/reset');
        store.dispatch('auth/logout');
        this.router.push({ path: '/login/mail' });
      });
      throw err;
    } else if (errorData.message === 'UnavailableIpPublicationPeriod') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        html: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      }).then((result) => {
        // コンテンツ一覧へ
        this.router.back();
      });
      throw err;
    } else if (errorData.message === 'UnavailableVideoPublicationPeriod') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        html: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      }).then((result) => {
        // コンテンツ一覧へ
        this.router.back();
      });
      throw err;
    } else if (errorData.message === 'DiscardedEmail') {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || errorData.code,
        html: text || errorData.message,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      }).then((result) => {
        // メール入力画面に
        this.router.back();
      });
      throw err;
    } else if (err.response.status === 401) {
      if (err.config.hasRetriedRequest || err.config.refresh) {
        store.dispatch('profile/reset');
        store.dispatch('auth/logout');
        this.router.push({ path: '/login' });
        throw err;
      } else {
        try {
          await store.dispatch('auth/tokenRefresh');
          return this.http({
            ...err.config,
            hasRetriedRequest: true,
            headers: {
              ...err.config.headers,
              Authorization: `Bearer ${Cookies.get('token')}`
            }
          });
        } catch (e) {
          throw e;
        }
      }
    } else if (err.response.status === 403) {
      store.dispatch('auth/logout');
      this.router.push({ path: '/login' });
    } else if (err.response.status === 404) {
      throw err;
    } else if (err.config.silent) {
      throw err;
    } else {
      const title = ERROR_TITLE[errorData.message];
      const text = ERROR_MESSAGE[errorData.message];
      Vue.swal({
        allowOutsideClick: false,
        title: title || '原因不明のエラー',
        text: text || device === 'web' ? '原因不明のエラーが発生しました。\n ページを更新してもう一度おためしてください。' : '原因不明のエラーが発生しました。\n アプリの再起動を行ってください。',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          container: 'withee-alert',
          confirmButton: 'red'
        }
      });
      throw err;
    }
  }
}

const http = axios.create();

export function initializeAxios (router) {
  service.register([new AuthMiddleware(http, router)]);
}
