import TimeAPI from "@/api/time";
import moment from "moment";

// initial state
const state = () => ({
  time: "",
  base: "",
});

// getters
const getters = {
  get: (state) => () => {
    if (!state.time) return moment();
    const diff = moment().diff(state.base, "milliseconds");
    return moment(state.time).add(diff, "milliseconds");
  },
};

// actions
const actions = {
  async get({ state, commit, rootGetters }, config = { silent: true }) {
    if (!rootGetters["auth/isLogin"]) return true;
    try {
      const res = await TimeAPI.get(config);
      if (res) {
        commit("set", res.data);
      }
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  set(state, data) {
    state.time = data.datetime;
    state.base = moment();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
