<template>
  <div>
    <CustomModal id="shopModal" class="shop" full @close="close" :is-ignore-set-height="true">
      <template v-slot:header>
        <span class="header-title">ショップ</span>
        <div class="header-coin">
          <img
            class="header-coin-icon"
            src="@/assets/images/common_icon_coin.png"
            alt=""
          />
          <span class="header-coin-value" v-currency="coinBalance.total"></span>
        </div>
        <div class="possession-coin">
          <div class="free-coin">
            <img
                class="header-coin-icon"
                src="@/assets/images/common_icon_coin.png"
                alt=""
            />
            <span class="coin-name">無償</span>
            <span
                class="header-coin-value"
                v-currency="coinBalance.free"
            ></span>
          </div>
          <div class="coin">
            <img
              class="header-coin-icon"
              src="@/assets/images/common_icon_coin.png"
              alt=""
            />
            <span class="coin-name">有償</span>
            <span
              class="header-coin-value"
              v-currency="coinBalance.paid"
            ></span>
          </div>
        </div>
      </template>
      <template v-slot:body v-if="list.length > 0">
        <ul>
          <template v-for="item in list">
            <template v-if="onPeriodCheck(item) && checkStock(item)">
              <li
                class="item-list"
                v-if="!item.isHidden"
                :key="item.shopCoinId"
              >
                <div v-if="item.salesCopy" class="bargain-text">
                  <span>{{ item.salesCopy }}</span>
                </div>
                <div class="item-list-left">
                  <img
                    class="header-coin-icon"
                    src="@/assets/images/common_icon_coin.png"
                    alt=""
                  />
                  <span class="coin">{{
                    checkCoinLimit(item.webPaidCoin)
                  }}</span>
                </div>
                <div class="item-list-right">
                  <span class="remain-count" v-if="item.maxQuantity > 0">{{
                    `あと` + item.remainingQuantity + `回`
                  }}</span>
                  <CommonButton
                    @click="onStatusInfo(item)"
                    class="coin-buy-btn"
                  >
                    {{ `¥` + checkCoinLimit(item.webPrice) }}
                  </CommonButton>
                </div>
                <div class="remain-time" v-if="item.hasShowedTimeLeft">
                  <img
                    class="clock-icon"
                    src="@/assets/images/common_icon_clock_day.png"
                    alt=""
                  />
                  <span v-html="moment(item.publicationEndTime)"></span>
                </div>
              </li>
            </template>
          </template>
        </ul>
      </template>
      <template v-slot:body v-else>
        <p class="not-stock-text">ただいま、購入できる販売物がありません</p>
      </template>
      <template v-slot:footer>
        <div class="modal-footer-inner">
          <ul>
            <li>
              <span @click="onContractModal">コインについて</span
              ><img
                class="arrow-right-icon"
                src="@/assets/images/common_btn_arrow_next.png"
                alt=""
              />
            </li>
            <li>
              <span @click="onCoinHisotry">コイン履歴</span
              ><img
                class="arrow-right-icon"
                src="@/assets/images/common_btn_arrow_next.png"
                alt=""
              />
            </li>
            <li>
              <span @click="showPaymentServices">資金決済法に基づく表示</span
              ><img
                class="arrow-right-icon"
                src="@/assets/images/common_btn_arrow_next.png"
                alt=""
              />
            </li>
            <li>
              <span @click="showCommercialTransactions">特定商取引法の表記</span
              ><img
                class="arrow-right-icon"
                src="@/assets/images/common_btn_arrow_next.png"
                alt=""
              />
            </li>
          </ul>
          <ul class="annotation">
            <li>・購入したコインに有効期限はありません。</li>
            <li>・有償コインはWEBブラウザでのみご利用になれます。</li>
            <li>
              ・有償コインはOS（iOS、Android、WEBブラウザ）ごとに所持上限が設定されています。
            </li>
            <li>
              ・コインの決済完了時に所持する有償コイン数が©︎9,999,999を超える場合は決済ができません。
            </li>
          </ul>
        </div>
      </template>
    </CustomModal>
    <CoinHistoryModal
      v-if="isHistoryDialog"
      :is-history-dialog="isHistoryDialog"
      @close="isHistoryDialog = false"
    />
    <RemoteConfigModal ref="RemoteConfigModal" />
    <CustomModal
      id="shopLimit"
      class="shopLimit"
      v-if="isShopLimitDialog"
      @close="isShopLimitDialog = false"
    >
      <template v-slot:header>
        <span class="header-title">所持上限です</span>
      </template>
      <template v-slot:body>
        <div>
          <p class="text-center">
            有償コインの所持上限を超えるため購入することができません。
          </p>
          <div class="limit-content">
            <div
              class="limit-content-inner"
            >
              <span>有償コイン所持上限</span>
              <span class="limit-content-info-wrap">
                  <img
                  class="header-coin-icon"
                  src="@/assets/images/common_icon_coin.png"
                  alt=""
                />
                <span class="limit-content-text">9,999,999</span>
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex flex-center">
          <CommonButton medium @click="isShopLimitDialog = false"
            >OK</CommonButton
          >
        </div>
      </template>
    </CustomModal>
    <CustomModal
      id="shopAgeLimit"
      class="shopAgeLimit"
      v-if="isAgeErrorDialog"
      @close="isAgeErrorDialog = false"
    >
      <template v-slot:header>
        <span class="header-title">購入上限です</span>
      </template>
      <template v-slot:body>
        <div>
          <p class="text-center">
            今月の購入上限金額を超えるため購入することができません。
          </p>
          <div class="limit-content">
            <div
              class="limit-content-inner"
              v-for="limit in limitList"
              :key="limit.currencyLimitId"
            >
              <span>{{ limit.name }}</span>
              <span><span v-currency:unit="limit.maxAmount"></span>まで</span>
            </div>
            <div class="attention">
              ※登録いただいた生年月日に基づいて1アカウントごとに上限金額が設定されます。
            </div>
          </div>
          <p class="text-center">
            大変お手数ですが、翌月1日以降に<br />再度ご購入くださいますようお願いいたします。
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex flex-center">
          <CommonButton medium @click="isAgeErrorDialog = false"
            >OK</CommonButton
          >
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import CustomModal from '@/components/CustomModal';
import CommonButton from '@/components/CommonButton';
import CoinHistoryModal from '@/components/CoinHistoryModal';
import RemoteConfigModal from '@/components/RemoteConfigModal.vue';
import resolution from '@/mixin/resolution';
import moment from 'moment';
import ShopAPI from '@/api/shop';

export default {
  name: 'ShopListModal',
  components: {
    CustomModal,
    CommonButton,
    CoinHistoryModal,
    RemoteConfigModal
  },
  props: {
    isShopDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [resolution],
  computed: {
    serverTime: {
      cache: false,
      get () {
        return this.$store.getters['time/get']();
      }
    },
    shop () {
      return this.$store.getters['shop/shop'];
    },
    list () {
      return this.shop?.list || [];
    },
    coinBalance () {
      return this.shop?.coinBalance || {};
    },
    limitList () {
      return this.$store.getters['currency/limit'] || [];
    }
  },
  data () {
    return {
      body: null,
      isAgeErrorDialog: false,
      isShopLimitDialog: false,
      isHistoryDialog: false,
      order: 'ASC',
      limit: 15,
      time: this.time
    };
  },
  watch: {},
  async mounted () {
    const payload = {
      'seek.order': this.order,
      'seek.limit': this.limit
    };
    await this.$store.dispatch('shop/get');
    await this.$store.dispatch('currency/limit');
    await this.$store.dispatch('currency/get', payload);
  },
  methods: {
    close (closeByPurchase = false) {
      this.$emit('close', !closeByPurchase);
    },
    moment (date) {
      const endTime = moment(date);
      const hours = endTime.diff(this.serverTime, 'hours');
      const minutes = endTime.diff(this.serverTime, 'minutes');
      const days = endTime.diff(this.serverTime, 'days');
      if (hours < 1) {
        return `<span class="fc-red">残り${minutes}分</span>`;
      } else if (hours > 24) {
        return `残り${days}日`;
      } else {
        return `<span class="fc-red">残り${hours}時間</span>`;
      }
    },
    checkCoinLimit (coin) {
      if (parseInt(coin, 10) > 9999999) {
        return '9,999,999+';
      }
      return Number(coin).toLocaleString();
    },
    async onStatusInfo (val) {
      if (!this.onPeriodCheck(val)) {
        this.$swal
          .fire({
            title: '購入期限エラー',
            text: `購入期限エラー`,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'red'
            }
          })
          .then((result) => {});
        return;
      }
      // 購入上限チェック
      try {
        await ShopAPI.validate(val.shopCoinId);
        this.selectImage = null;
        this.close(true);
        this.$router.push({
          path: `/purchase/${val.shopCoinId}`,
          query: { url: window.location.href }
        });
      } catch (err) {
        let data = err.response.data;
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        if (data.message === 'MaxQuantityExceed') {
          // limitError
          // 所持上限
          this.isShopLimitDialog = true;
        } else if (data.message === 'MaxAmountExceed') {
          // AgeError
          // 課金上限
          this.isAgeErrorDialog = true;
        }
      }
    },
    onCoinHisotry () {
      this.$data.isHistoryDialog = true;
    },
    // 契約事項
    onContractModal () {
      this.$router.push({ path: '/account/faq', query: { type: 'ショップ' } });
      // this.close();
    },
    showPaymentServices () {
      this.$refs.RemoteConfigModal.open(
        '資金決済法に基づく表示',
        'PAYMENT_SERVICES'
      );
    },
    showCommercialTransactions () {
      this.$refs.RemoteConfigModal.open(
        '特定商取引法に基づく表記',
        'SPECIFIED_COMMERCIAL_TRANSACTIONS'
      );
    },
    // 購入期限チェック
    onPeriodCheck (item) {
      const serverTime = this.serverTime;
      const publicationEndTime = moment(
        moment(item.publicationEndTime).format('YYYY-MM-DD HH:mm:ss')
      );
      const publicationStartTime = moment(
        moment(item.publicationStartTime).format('YYYY-MM-DD HH:mm:ss')
      );
      const isAfter = serverTime.isAfter(publicationEndTime);
      const isBefore = serverTime.isBefore(publicationStartTime);
      return !isAfter && !isBefore;
    },
    // 在庫チェック
    checkStock (item) {
      if (Number(item.maxQuantity) === 0) return true;
      return Number(item.remainingQuantity) > 0;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.limit-content {
  padding: 16px 24px;
  margin: 24px 0;
  border-radius: 8px;
  background-color: $baseWhite;
  .limit-content-inner {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    color: $baseDark;
    .limit-content-info-wrap {
      display: flex;
      align-items: center;
      .header-coin-icon {
        width: 20px;
        height: 20px;
        margin-right: 9px;
      }
    }
  }
  .attention {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 1.12px;
    color: $red;
    text-align: center;
  }
}
.contract-content {
  dl {
    text-align: left;
    line-break: normal;
    dt,
    dd {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 1.6px;
      color: #5f6368;
    }
    dd {
      padding: 5px 0 10px;
      font-size: 14px;
      letter-spacing: 1.12px;
    }
  }
}
.remote-config-modal {
  .header-title {
    display: block;
    margin: 30px 0;
    color: $baseBlack;
    font-weight: normal;
  }
}
::v-deep.shop {
  &.modal-backdrop {
    &.full {
      .modal-body {
        .not-stock-text {
          padding: 20rem 0;
          text-align: center;
          font-size: 2rem;
        }
      }
      .modal-close-btn {
        //top: 30px;
        //z-index: 1;
      }
      .modal-header {
        display: block;
        text-align: center;
        width: 100%;
        height: 228px;
        box-shadow: 0px 4px 16px 0 rgba(42, 44, 46, 0.1);
        background-color: #fff;
        .header-title {
          display: block;
          font-size: 3.2rem;
          padding: 30px 0;
          font-weight: normal;
          color: $baseBlack;
        }
        .header-coin {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px;
          height: 50px;
          margin: 0 auto;
          padding: 0 8px;
          border-radius: 25px;
          background: $baseWhite;
          font-size: 1.2rem;
          .header-coin-icon {
            width: 16px;
            height: 16px;
          }
          .header-coin-value {
            padding-left: 10px;
            font-size: 20px;
            font-weight: normal;
            color: $baseBlack;
          }
        }
        .possession-coin {
          display: flex;
          justify-content: center;
          padding: 10px 0 0;
          font-size: 16px;
          .coin,
          .free-coin {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .coin {
            padding-left: 20px;
          }
          .coin-name {
            padding: 0 10px 0 5px;
            color: $yellow;
          }
          .header-coin-value {
            color: $baseDark;
            font-weight: normal;
          }
          .header-coin-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
      .modal-footer {
        display: block;
        padding: 15px;
        width: 100%;
        height: auto !important;
        box-shadow: 0px 4px 16px 0 rgba(42, 44, 46, 0.1);
        background-color: #fff;
        .modal-footer-inner {
          li {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.19;
            letter-spacing: 1.28px;
            text-align: left;
            line-break: normal;
            color: $baseDark;
            span {
              color: $baseBlack;
              cursor: pointer;
            }
            .arrow-right-icon {
              width: 7px;
              height: 8px;
              padding-left: 10px;
              box-sizing: content-box;
            }
          }
        }
      }
    }
  }
  .item-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 800px;
    height: 98px;
    margin: 24px auto;
    padding: 0 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 2px 0 rgb(95 99 104 / 10%);

    .bargain-text {
      position: absolute;
      top: -10px;
      left: 0;
      width: 260px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 4px;
      background-color: #ff4d6a;
      span {
        width: 231px;
        height: 16px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.28px;
        text-align: center;
        color: #fff;
      }
    }

    .item-list-left {
      display: flex;
      align-items: center;

      .header-coin-icon {
        width: 19px;
        height: 19px;
      }
      .coin {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        padding-left: 5px;
        letter-spacing: 0.8px;
        color: #5f6368;
      }
    }

    .item-list-right {
      display: flex;
      align-items: center;

      .remain-count {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        padding-right: 30px;
        letter-spacing: 0.96px;
        color: $red;
      }
      .coin-buy-btn {
        width: 160px;
        height: 40px;
        font-weight: normal;
      }
    }

    .remain-time {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 5px;

      .clock-icon {
        width: 14px;
        height: 14px;
      }
      span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        padding-left: 5px;
        line-height: 1.75;
        letter-spacing: 1.28px;
        color: $baseDark;
        &.time {
          color: $red;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  ::v-deep.shop {
    &.modal-backdrop {
      &.full {
        .modal-header {
          height: 120px;
          .header-title {
            padding: 10px;
            font-size: 1.6rem;
          }
          .header-coin {
            width: 175px;
            height: 35px;
            .header-coin-value {
              font-size: 1.6rem;
            }
          }
          .possession-coin {
            font-size: 1.2rem;
          }
        }
        .modal-body {
          .not-stock-text {
            padding: 10rem 0;
            font-size: 1.5rem;
          }
          .item-list {
            width: 100%;
            height: 60px;
            margin: 14px auto;
            padding: 0 12px;
            .bargain-text {
              top: -5px;
              width: 150px;
              height: 16px;
              line-height: 16px;
              span {
                font-size: 8px;
                letter-spacing: 0.64px;
              }
            }
            .item-list-left {
              font-size: 14px;
              .header-coin-icon {
                width: 14px;
                height: 14px;
              }
              .coin {
                font-size: 14px;
              }
            }
            .item-list-right {
              .remain-count {
                padding-right: 10px;
                font-size: 10px;
              }
              .coin-buy-btn {
                width: 95px;
                height: 25px;
              }
            }
            .remain-time {
              bottom: 4px;
              .clock-icon {
                width: 8px;
                height: 8px;
              }
              span {
                font-size: 8px;
              }
            }
          }
        }
        .modal-footer {
          .modal-footer-inner {
            li {
              justify-content: space-between;
              position: relative;
              span {
                font-size: 12px;
              }
              .arrow-right-icon {
                width: 5px;
                height: 8px;
              }
              a {
                .arrow-right-icon {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
            .annotation {
              li {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  .limit-content {
    padding: 12px 18px;
    margin: 12px 0;
    .limit-content-inner {
      font-size: 12px;
      .limit-content-info-wrap {
        display: flex;
        align-items: center;
        .header-coin-icon {
          width: 14px;
          height: 14px;
          margin-right: 9px;
        }
      }
    }
    .attention {
      font-size: 10px;
    }
  }

  .contract-content {
    dl {
      dt {
        font-size: 12px;
      }
      dd {
        font-size: 10px;
      }
    }
  }
}
</style>
