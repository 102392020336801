import PostAPI from '@/api/post';
import { cloneDeep } from 'lodash';
import BlockAPI from '@/api/block';

// initial state
const state = () => ({
  list: [],
  data: null,
  seek: null,
  mediaKeys: []
});

// getters
const getters = {
  list: (state) => state.list,
  data: (state) => state.data,
  seek: (state) => state.seek,
  mediaKeys: (state) => state.mediaKeys
};

// actions
const actions = {

  async list({ state, commit }, payload = { silent: false }) {
    try {
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('setList', res.data.list);
        state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  async reload({ state, commit }, payload = { silent: false }) {
    try {
      payload.query.seek.lastCreateTime = state.seek?.lastCreateTime;
      payload.query.seek.limit = state.list.length;
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('setList', res.data.list);
        state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit }, payload = { silent: false }) {
    try {
      const res = await PostAPI.get(payload);
      if (res.data) {
        state.data = res.data;
        state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  async next({ state, commit }, payload = { silent: false }) {
    try {
      payload.query.seek.lastCreateTime = state.seek?.lastCreateTime;
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('addList', res.data.list);
        state.seek = res.data.seek;
      }
    } catch (e) {
      state.seek = null;
      throw e;
    }
  },
  async create({ state, commit, rootGetters }, data = { silent: false }) {
    try {
      const res = await PostAPI.create(data);
      // const profile = rootGetters['profile/getProfile'];
      // profile.avatarImageUrl = profile.imageUrl;
      commit('add', res.data);
    } catch (e) {
      throw e;
    }
  },
  async edit({ state, commit }, data = { silent: false }) {
    try {
      await PostAPI.edit(data);
      commit('edit', data);
    } catch (e) {
      throw e;
    }
  },
  async delete({ state, commit }, data = { silent: false }) {
    try {
      await PostAPI.delete(data);
      commit('delete', data);
    } catch (e) {
      throw e;
    }
  },
  async media({ state }, data) {
    try {
      const res = await PostAPI.media(data);
      state.mediaKeys = res.data;
    } catch (e) {
      throw e;
    }
  },
  clearMedia({ state }) {
    state.mediaKeys = [];
  },
  async block({ state, commit, rootGetters }, data = { silent: false }) {
    try {
      await BlockAPI.create(data);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },
  addList(state, data) {
    if (state.list.length <= 0) {
      state.list = data;
    } else {
      state.list = [...state.list, ...data];
    }
  },
  resetProfile(state) {
    state.profile = null;
  },
  add(state, data) {
    if (data.depth === '0') data.rootPostId = data.postId;
    const list = cloneDeep(state.list);
    list.unshift(data);
    state.list = list;
  },
  edit(state, data) {
    if (state.data && state.data.postId === data.postId) {
      const _data = cloneDeep(state.data);
      _data.text = data.payload.text;
      state.data = _data;
    }
    const index = state.list.findIndex((post) => post.postId === data.postId);
    if (index >= 0) {
      const list = cloneDeep(state.list);
      list[index].text = data.payload.text;
      state.list = list;
    }
  },
  delete(state, data) {
    if (state.data && state.data.postId === data.postId) {
      state.data = null;
    }
    const index = state.list.findIndex((post) => post.postId === data.postId);
    if (index >= 0) {
      const list = cloneDeep(state.list);
      list.splice(index, 1);
      state.list = list;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
