import SpecialAPI from '@/api/special';
import { cloneDeep } from 'lodash';

// initial state
const state = () => ({
  all: [],
  seek: null
});

// getters
const getters = {
  all: (state) => state.all,
  seek: (state) => state.seek
};

// actions
const actions = {
  async list({ state, commit }, payload = null) {
    try {
      const res = await SpecialAPI.list(payload);
      if (res) {
        commit('setList', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async add({ state, commit }, payload = null) {
    try {
      const res = await SpecialAPI.list(payload);
      if (res) {
        commit('addList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  reset({ state }) {
    state.all = [];
    state.seek = null;
  }
};

const mutations = {
  setList(state, data) {
    state.all = data.specials;
    state.seek = data.seek;
  },
  addList(state, data) {
    const all = cloneDeep(state.all);
    all.push(...data.specials);
    state.all = all;
    state.seek = data.seek;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
