import IPAPI from '@/api/ip';

// initial state
const state = () => ({
  list: [],
  visited: [],
  ip: null,
  overview: null,
  total: 0
});

// getters
const getters = {
  list: (state) => state.list,
  ip: (state) => state.ip,
  overview: (state) => state.overview,
  total: (state) => state.total,
  firstVisit: (state) => (id) => !state.visited.includes(id)
};

// actions
const actions = {
  async list({ state, commit }, params = null) {
    // if (state.list.length) return;
    try {
      const res = await IPAPI.list(params);
      if (res.data) {
        commit('setList', res.data.list);
        state.total = Number(res.data.total);
      }
    } catch (e) {
      throw e;
    }
  },
  async addList({ state, commit }, params = null) {
    try {
      const res = await IPAPI.list(params);
      if (res.data) {
        commit('addList', res.data.list);
        state.total = Number(res.data.total);
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit, rootGetters }, id) {
    if (id) {
      try {
        if (rootGetters['auth/isLogin']) {
          const res = await IPAPI.get(id);
          if (res.data) {
            commit('set', res.data);
          }
        } else {
          const res = await IPAPI.getNoLogin(id);
          if (res.data) {
            commit('set', res.data);
          }
        }
      } catch (e) {
        throw e;
      }
    } else {
      commit('set', null);
    }
  },
  async overview({ state, commit }, id) {
    try {
      state.overview = null;
      const res = await IPAPI.overview(id);
      if (res.data) {
        state.overview = res.data;
      }
    } catch (e) {
      throw e;
    }
  },
  async follow({ state, commit, dispatch }, id) {
    try {
      commit('follow', id);
      await IPAPI.follow(id);
      dispatch('profile/get', { silent: true }, { root: true });
    } catch (e) {
      commit('unfollow', id);
      throw e;
    }
  },
  async unfollow({ state, commit, dispatch }, id) {
    try {
      commit('unfollow', id);
      await IPAPI.unfollow(id);
      dispatch('profile/get', { silent: true }, { root: true });
    } catch (e) {
      commit('follow', id);
      throw e;
    }
  },
  setVisit({ state, commit, dispatch }, id) {
    if (!state.visited.includes(id)) {
      const list = state.visited;
      list.push(id);
      state.visited = list;
    }
  },
  reset({ state }) {
    state.list = [];
    // state.visited = [];
    state.ip = null;
    state.overview = null;
    state.total = 0;
  }
};

const mutations = {
  set(state, data) {
    state.ip = data;
  },
  setList(state, data) {
    state.list = data;
  },
  addList(state, data) {
    if (state.list.length <= 0) {
      state.list = data;
    } else {
      state.list = [...state.list, ...data];
    }
  },
  follow(state, id) {
    const list = [...state.list];
    state.list = list.map((item) => {
      if (item.ipId === id) {
        item.hasFollowed = true;
      }
      return item;
    });
  },
  unfollow(state, id) {
    const list = [...state.list];
    state.list = list.map((item) => {
      if (item.ipId === id) {
        item.hasFollowed = false;
      }
      return item;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
