import NotificationAPI from "@/api/notification";
import _ from "lodash";

// initial state
const state = () => ({
  list: [],
  seek: null,
  data: null,
  error: null,
  unreadCount: "",
  hasLimitExceededItems: false,
});

// getters
const getters = {
  getUnreadCount: (state) => state.unreadCount,
  getList: (state) => state.list,
  getSeek: (state) => state.seek,
  get: (state) => state.data,
  error: (state) => state.error,
  getHasLimitExceeded: (state) => state.hasLimitExceededItems,
};

// actions
const actions = {
  async list({ state, commit }, payload) {
    try {
      const _payload = Object.assign({}, payload);
      delete payload.type;
      const res = await NotificationAPI.list(payload);
      console.log("/---- _payload : ", _payload);
      if (res.data) {
        if (_payload.type === "add") {
          commit("addList", res.data);
        } else {
          commit("setList", res.data);
        }
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit }, payload) {
    // const notice = state.list.find((element) => element.noticeId === payload.noticeId);
    // if (notice) {
    //   commit('set', notice);
    // } else {
    try {
      const res = await NotificationAPI.get(payload);
      if (res.data) {
        commit("set", res.data.notice);
      }
    } catch (e) {
      throw e;
    }
    // }
  },
  async read({ state, commit }, id) {
    try {
      await NotificationAPI.read(id);
    } catch (e) {
      throw e;
    }
  },
  async acquire({ state, commit }, id) {
    try {
      const res = await NotificationAPI.acquire(id);
      if (_.has(res.data, "hasLimitExceededItems")) {
        commit("setLimitExceeded", res.data.hasLimitExceededItems);
      } else {
        commit("setLimitExceeded", false);
      }
    } catch (e) {
      throw e;
    }
  },
  acquireError({ state }, payload = null) {
    state.error = payload;
  },
  async unreadCount({ state, commit }, payload) {
    try {
      const res = await NotificationAPI.unreadCount(payload);
      commit("setUnreadCount", res.data.noticeUnreadCount);
    } catch (e) {
      throw e;
    }
  },
  resetLimitExceeded({ state, commit }, payload = null) {
    commit("setLimitExceeded", false);
  },
};

const mutations = {
  setList(state, data) {
    state.list = data.lists;
    state.seek = data.seek;
  },
  set(state, data) {
    state.data = data;
  },
  addList(state, data) {
    const _list = _.cloneDeep(state.list);
    _list.push(...data.lists);
    state.list = _list;
    state.seek = data.seek;
  },
  setUnreadCount(state, data) {
    state.unreadCount = data;
  },
  setLimitExceeded(state, data) {
    state.hasLimitExceededItems = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
