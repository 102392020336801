import StoryAPI from '@/api/story';
import { cloneDeep } from 'lodash';

// initial state
const state = () => ({
  all: [],
  seek: null,
  data: null,
  ip: null
});

// getters
const getters = {
  all: (state) => state.all,
  seek: (state) => state.seek,
  get: (state) => state.data,
  getIp: (state) => state.ip
};

// actions
const actions = {
  async list({ state, commit }, payload = null) {
    try {
      const res = await StoryAPI.list(payload);
      if (res) {
        commit('setList', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async add({ state, commit }, payload = null) {
    try {
      const res = await StoryAPI.list(payload);
      if (res) {
        commit('addList', res.data);
      }
    } catch (e) {
    throw e;
    }
  },
  async get({ state, commit }, payload) {
    if (payload) {
      try {
        const res = await StoryAPI.get(payload);
        if (res) {
          commit('setData', res.data);
        }
      } catch (e) {
        throw e;
      }
    } else {
      commit('setData', null);
    }
  },
  async getIp({ state, commit }, ipId) {
    if (ipId) {
      try {
        const res = await StoryAPI.getIp(ipId);
        if (res) {
          commit('setIp', res.data);
        }
      } catch (e) {
        throw e;
      }
    } else {
      commit('setIp', null);
    }
  },
  async purchase({ commit }, payload) {
    try {
      await StoryAPI.purchase(payload);
      commit('updateData', payload);
    } catch (e) {
      throw e;
    }
  },
  reset({ commit }) {
    commit('reset');
  }
};

const mutations = {
  setList(state, data) {
    state.all = data.list;
    state.seek = data.seek;
  },
  addList(state, data) {
    const all = cloneDeep(state.all);
    all.push(...data.list);
    state.all = all;
    state.seek = data.seek;
  },
  updateData(state, data) {
    const index = state.data.episodeList.findIndex((story) => story.storyEpisodeId === data.story.storyEpisodeId);
    if (index >= 0) {
      const all = cloneDeep(state.data.episodeList);
      if (data.story.badge === 'PURCHASE') {
        all[index].badge = 'PURCHASED';
      } else {
        if (data.isCharging) {
          all[index].badge = 'CHARGED';
        } else {
          all[index].badge = 'PURCHASED';
        }
      }
      state.data.episodeList = all;
    }
  },
  setData(state, data) {
    state.data = data;
  },
  setIp(state, data) {
    state.ip = data;
  },
  reset(state) {
    state.all = [];
    state.seek = null;
    state.data = null;
    state.ip = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
